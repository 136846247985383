import React from 'react'
import '../App.css'
// import HeroBg from '../Assets/Hero_bg.jpg'

const Landing = () => {
  return (
    <div id='HeroBg'>
      
    </div>
  )
}

export default Landing
